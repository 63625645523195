<!-- <div *ngIf="loading" class="full-page-spinner">
    <div class="spinner-border" role="status">
    </div>
</div> -->

<div class="row">
  <div class="col-12">
    <div class="card card-rounded p-2">
      <div class="card-body search-text">
        <div class="row mb-2">
          <div class="col-3">
            <label>ค้นหาโดย</label>
            <!-- (change)="onSearchByModeChanged($event)" -->
            <ng-select class="w-100" [(ngModel)]="searchByMode" [clearable]="false">
              <ng-option [value]="0">ชื่อลูกค้า</ng-option>
              <ng-option [value]="1">เลขทะเบียน 13 หลัก</ng-option>
            </ng-select>
          </div>

          <div class="col-3">
            <label>ค้นหาลูกค้า</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon-search1">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Search..." aria-label="Search..."
                [(ngModel)]="customerNameSearch" aria-describedby="basic-addon-search1" />
            </div>
          </div>


        </div>
        <div class="row">
          <div class="col-3">
            <label>ประเภทการชำระ</label>
            <!-- (change)="onSearchByModeChanged($event)" -->
            <ng-select class="w-100" [(ngModel)]="searchByPaymentType" [clearable]="false">
              <ng-option [value]="null">ทั้งหมด</ng-option>
              <ng-option *ngFor="let paymentType of paymentTypeList" [value]="paymentType.id">{{
                paymentType.name }}</ng-option>

            </ng-select>
          </div>
          <div class="col-3">
            <label>รอบที่สร้างเอกสาร</label>
            <ng-select class="w-100" [(ngModel)]="selectedPeriodSearch" [multiple]="true"
              (change)="onSelectPeriodChanged($event)" [clearable]="false" [disabled]="viewByInvoice">
              <ng-option *ngFor="let item of dropdownPeriods" [value]="item.value">
                {{ item.desc }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-3">
            <label>สถานะ</label>
            <ng-select class="w-100" [(ngModel)]="selectedStatus" (ngModelChange)="onSelectChanged()"
              [clearable]="false">
              <ng-option *ngFor="let item of statusList" [value]="item.id">
                {{ item.myWorkStatusTh }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-3">
            <ng-container>
              <label>เอกสาร</label>
              <ng-select class="w-100" [(ngModel)]="selectedDocumentItemSearch" [multiple]="true"
                (change)="onSelectDocItemChanged($event)" [clearable]="false" [closeOnSelect]="false" [disabled]="
                  userRole == userRoleEnum.Finance ||
                  userRole == userRoleEnum.FinanceSupervisor ||
                  userRole == userRoleEnum.FinanceManager
                ">
                <ng-option *ngFor="let item of dropdownCustomerHaveItemRespones" [value]="item.value">
                  {{ item.desc }}
                </ng-option>
              </ng-select>
            </ng-container>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col"><label>เอกสารประจำเดือน</label></div>
        </div>
        <div class="row">
          <div class="monthly-doc-group">
            <div class="col">
              <div class="row my-1">
                <div class="col-3">
                  <label>วันที่สร้างเอกสาร จากปี</label>
                  <ng-select class="w-100" [(ngModel)]="startYear" (change)="getDropdownForSearchStartMonth()"
                    [clearable]="false">
                    <ng-option *ngFor="let item of dropdownYears" [value]="item.value">
                      {{ item.desc }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-3">
                  <label>เดือน</label>
                  <ng-select class="w-100" [(ngModel)]="startMonth" (change)="onSelecStartMonth()" [clearable]="false">
                    <ng-option *ngFor="let item of dropdownStartMonth" [value]="item.value">
                      {{ item.desc }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-3">
                  <label>ถึงปี</label>
                  <ng-select class="w-100" [(ngModel)]="endYear" (change)="getDropdownForSearchEndMonth()"
                    [clearable]="false">
                    <ng-option *ngFor="let item of dropdownEndYears" [value]="item.value">
                      {{ item.desc }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-3">
                  <label>เดือน</label>
                  <ng-select class="w-100" [(ngModel)]="endMonth" (change)="onSelectMonthChanged()" [clearable]="false">
                    <ng-option *ngFor="let item of dropdownEndMonth" [value]="item.value">
                      {{ item.desc }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="monthly-doc-group"
            *ngIf="userRole != userRoleEnum.Accountant && userRole != userRoleEnum.Finance">
            <div class="col-3 mb-1">
              <label for="" class="mt-1">พนักงาน</label>
              <ng-select [(ngModel)]="selectedEmployee">
                <ng-option *ngFor="let employee of employeeList" [value]="employee">
                  {{
                  employee.firstName +
                  " " +
                  employee.lastName +
                  " " +
                  " (" +
                  employee.teamTh +
                  ")"
                  }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-3">
          <div class="col-lg-2 col-md-3 col-12">
            <button class="btn btn-main w-100" (click)="onSearchClicked()">
              Search
            </button>
          </div>
          <div class="col-lg-2 col-md-3 col-12">
            <button class="btn btn-cancel w-100" (click)="onReset()">
              Reset
            </button>
          </div>
        </div>

        <div>
          <button (click)="checkselected()" class='btn btn-primary' style="display: none;">check selected</button>
        </div>

      </div>
    </div>
    <div class="row justify-content-between align-items-center mb-2">
      <div class="col-2">
        <ng-container *ngIf="
            userRole !== userRoleEnum.Finance &&
            userRole !== userRoleEnum.FinanceManager &&
            userRole !== userRoleEnum.FinanceSupervisor
          ">
          <label for="">รูปแบบการแสดงข้อมูล</label>
          <select [(ngModel)]="viewByInvoice" (change)="onViewModeChange()" class="form-control mr-3">
            <option [ngValue]="false">แบบรอบ</option>
            <option [ngValue]="true">แบบใบแจ้งหนี้</option>
          </select>
        </ng-container>
        <button *ngIf="selectItemToReportList.length >= 1  && userRole == userRoleEnum.FinanceManager && viewByInvoice"
          class="btn btn-warning" (click)="completeSelectedJob(selectItemToReportList)">Complete Selected</button>
      </div>


      <div class="col-2 justify-content-end align-items-center">
        <div class="d-flex justify-content-end align-items-center mb-1">
          <input type="checkbox" class="default-checkbox" [checked]="isWantFullReport" [(ngModel)]="isWantFullReport" />
          <p class="mx-1 mb-0">ออกรายงานข้อมูลทั้งหมด</p>
        </div>
        <a (click)="onDownloadFile('', '')">
          <img src="../../../../../assets/images/icons/button-icons/Download_Icon.svg" alt="Download" class="w-100" />
        </a>
      </div>
    </div>
    <div class="">
      <div class="card" *ngIf="userRole !== userRoleEnum.Accountant">
        <div class="card-body p-0">
          <div class="table-responsive" #NonAccountantTableWrapper (scroll)="syncScroll($event)">
            <div class="non-accountant-table" [ngClass]="{
                'viewby-invoice': viewByInvoice,
                'not-viewby-invoice': !viewByInvoice
              }">
              <table class="table">
                <thead class="my-works-header container-xxl p-0 is-sticky-top-window" #stickyNonAccountantHeader
                  *ngIf="isShowStickyHeader">
                  <ng-container *ngTemplateOutlet="NonAccTableheaderDetail"></ng-container>
                </thead>
                <thead class="my-works-header" #NotStickyNonAccountantHeader>
                  <ng-container *ngTemplateOutlet="NonAccTableheaderDetail"></ng-container>
                </thead>
                <tbody #NonAccountantDataTable>
                  <tr *ngIf="tableListData.length <= 0">
                    <td colspan="100%" class="text-center">ไม่มีข้อมูลงาน</td>
                  </tr>
                  <tr *ngFor="let data of tableListData; let i = index">
                    <td class="text-center" scope="col">
                      <input type="checkbox" class="default-checkbox" [checked]="data.isChecked"
                        (change)="selectItem($event.target.checked, data)" />
                    </td>

                    <td class="text-center">
                      {{ (page - 1) * pageLength + i + 1 }}
                    </td>
                    <td class="text-start table-border-right">
                      <div class="name-tolltip">
                        <div class="d-flex justify-content-between" style="overflow: hidden; text-overflow: ellipsis">
                          <a (click)="
                              onMyTaskClicked(
                                data.customerId,
                                data.monthNumber,
                                data.yearNumber
                              )
                            " *ngIf="!viewByInvoice && data.isFullPeriodModel" class="text-truncate-1"
                            [ngStyle]="{'max-width': (!financeRejectedCondition(data.myWorksStatusId) && userRole == userRoleEnum.FinanceManager) ? '175px' : null}">
                            {{ data.customerName }}
                          </a>
                          <a (click)="
                              onMyTaskOnePeriodClicked(data.documentTimingId)
                            " class="text-truncate-1" *ngIf="!viewByInvoice && !data.isFullPeriodModel"
                            [ngStyle]="{'max-width': (!financeRejectedCondition(data.myWorksStatusId) && userRole == userRoleEnum.FinanceManager) ? '175px' : null}">
                            {{ data.customerName }}
                          </a>
                          <a (click)="onInvoiceClicked(data.invoiceId)" class="text-truncate-1" *ngIf="viewByInvoice"
                            [ngStyle]="{'max-width': (!financeRejectedCondition(data.myWorksStatusId) && userRole == userRoleEnum.FinanceManager) ? '175px' : null}">
                            {{ data.customerName }}
                          </a>

                          <!-- [ngStyle]="{'max-width': (!financeRejectedCondition(data.myWorksStatusId) && userRole == userRoleEnum.FinanceManager) ? '175px' : null}" -->
                          <button class="btn btn-warning"
                            *ngIf="!financeRejectedCondition(data.myWorksStatusId) && userRole == userRoleEnum.FinanceManager && status != 65536"
                            (click)="completeJob(data.invoiceId)">
                            Complete
                          </button>

                        </div>
                        <div class="tolltip">
                          {{ data.customerName }}
                        </div>
                      </div>
                    </td>
                    <td class="text-center table-border-right" *ngIf="!viewByInvoice">
                      {{ data.periodNumber }}
                    </td>
                    <td class="text-center table-border-right" *ngIf="viewByInvoice">
                      <a (click)="onInvoiceClicked(data.invoiceId)">
                        {{ data.invoiceCode }}
                      </a>
                    </td>
                    <td class="text-center table-border-right">
                      <!-- {{ getMonthName(data.monthNumber) }} -->
                      {{ data.monthNameTh }}
                    </td>
                    <td class="text-center table-border-right">
                      {{ toNumber(data.yearNumber) + 543 }}
                    </td>

                    <ng-container *ngIf="
                        userRole !== userRoleEnum.Finance &&
                        userRole !== userRoleEnum.FinanceSupervisor &&
                        userRole !== userRoleEnum.FinanceManager
                      ">
                      <td class="text-right table-border-right" style="min-width: 180px"
                        *ngFor="let item of data.documentValueList">
                        <div>
                          <div style="cursor: pointer" ngbTooltip="{{
  item.remarks ? item.remarks : ''
}}">
                            <div *ngIf="item.paymentType === 0">
                              <div class="text-primary d-flex justify-content-end">
                                <ng-container *ngIf="
        item.isSubmitTaxForm;
        else docMarkerTemplate1
      ">
                                  <div class="text-warning mr-1">
                                    <i data-feather="star" [size]="18"></i>
                                  </div>
                                </ng-container>
                                <ng-template #docMarkerTemplate1>
                                  <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                    <i data-feather="check-square" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                    <i data-feather="x" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                    <i data-feather="alert-circle" [size]="18"></i>
                                  </div>
                                </ng-template>

                                {{ item.price }}
                              </div>
                            </div>
                            <div *ngIf="item.paymentType === 1">
                              <div class="text-success d-flex justify-content-end">
                                <ng-container *ngIf="
                                          item.isSubmitTaxForm;
                                          else docMarkerTemplate2
                                          ">
                                  <div class="text-warning mr-1">
                                    <i data-feather="star" [size]="18"></i>
                                  </div>
                                </ng-container>
                                <ng-template #docMarkerTemplate2>
                                  <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                    <i data-feather="check-square" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                    <i data-feather="x" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                    <i data-feather="alert-circle" [size]="18"></i>
                                  </div>
                                </ng-template>
                                {{ item.price }}
                              </div>
                            </div>
                            <div *ngIf="
    item.paymentType !== 1 &&
    item.paymentType !== 0
  ">
                              <div class="d-flex justify-content-end">
                                <ng-container *ngIf="
                                          item.isSubmitTaxForm;
                                          else docMarkerTemplate3
                                          ">
                                  <div class="text-warning mr-1">
                                    <i data-feather="star" [size]="18"></i>
                                  </div>
                                </ng-container>
                                <ng-template #docMarkerTemplate3>
                                  <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                    <i data-feather="check-square" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                    <i data-feather="x" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                    <i data-feather="alert-circle" [size]="18"></i>
                                  </div>
                                </ng-template>
                                {{ item.price }}
                              </div>
                            </div>

                            <hr />

                            <div *ngIf="item.paymentType === 0">
                              <div class="text-pink d-flex justify-content-end">
                                <ng-container *ngIf="
        item.isSubmitTaxForm;
        else docMarkerTemplate4
      ">
                                  <div class="text-warning mr-1">
                                    <i data-feather="star" [size]="18"></i>
                                  </div>
                                </ng-container>
                                <ng-template #docMarkerTemplate4>
                                  <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                    <i data-feather="check-square" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                    <i data-feather="x" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                    <i data-feather="alert-circle" [size]="18"></i>
                                  </div>
                                </ng-template>
                                {{ item.fineAmount }}
                              </div>
                            </div>
                            <div *ngIf="item.paymentType === 1">
                              <div class="text-danger d-flex justify-content-end">
                                <ng-container *ngIf="
        item.isSubmitTaxForm;
        else docMarkerTemplate5
      ">
                                  <div class="text-warning mr-1">
                                    <i data-feather="star" [size]="18"></i>
                                  </div>
                                </ng-container>
                                <ng-template #docMarkerTemplate5>
                                  <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                    <i data-feather="check-square" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                    <i data-feather="x" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                    <i data-feather="alert-circle" [size]="18"></i>
                                  </div>
                                </ng-template>
                                {{ item.fineAmount }}
                              </div>
                            </div>
                            <div *ngIf="
    item.paymentType !== 1 &&
    item.paymentType !== 0
  ">
                              <div class="d-flex justify-content-end">
                                <ng-container *ngIf="
        item.isSubmitTaxForm;
        else docMarkerTemplate6
      ">
                                  <div class="text-warning mr-1">
                                    <i data-feather="star" [size]="18"></i>
                                  </div>
                                </ng-container>
                                <ng-template #docMarkerTemplate6>
                                  <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                    <i data-feather="check-square" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                    <i data-feather="x" [size]="18"></i>
                                  </div>
                                  <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                    <i data-feather="alert-circle" [size]="18"></i>
                                  </div>
                                </ng-template>
                                {{ item.fineAmount }}
                              </div>
                            </div>
                          </div>

                        </div>
                      </td>
                    </ng-container>

                    <td class="text-right table-border-right" style="min-width: 180px">
                      <div class="d-flex justify-content-end">
                        <div class="text-success mr-1" *ngIf="data.isServiceMarkClear">
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isServiceReject">
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isServiceOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ data.summationOfAllPriceService }}
                      </div>
                    </td>
                    <td class="text-right table-border-right" style="min-width: 180px">
                      <div class="d-flex justify-content-end">
                        <div class="text-success mr-1" *ngIf="data.isPriceMarkClear">
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isPriceReject">
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isPriceOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ data.summationOfPrice }}
                      </div>
                    </td>
                    <td class="text-right table-border-right" style="min-width: 180px">
                      <div class="d-flex justify-content-end">
                        <div class="text-success mr-1" *ngIf="data.isFineMarkClear">
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isFineReject">
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isFineOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ data.summationOfFine }}
                      </div>
                    </td>
                    <ng-container *ngIf="
                        userRole !== userRoleEnum.Finance &&
                        userRole !== userRoleEnum.FinanceSupervisor &&
                        userRole !== userRoleEnum.FinanceManager
                      ">
                      <td class="text-right table-border-right" style="min-width: 180px">
                        {{ data.totalPrice }}
                      </td>
                    </ng-container>
                    <td class="text-center" [ngClass]="{
                        'statusColor-black': data.myWorksStatusId == 1,
                        'statusColor-gray':
                          data.myWorksStatusId == 32768 ||
                          data.myWorksStatusId == 65536 ||
                          data.myWorksStatusId == 131072,
                        'statusColor-red':
                          data.myWorksStatusId == 16 ||
                          data.myWorksStatusId == 32 ||
                          data.myWorksStatusId == 8192 ||
                          data.myWorksStatusId == 16384,
                        'statusColor-yellow':
                          data.myWorksStatusId == 2 ||
                          data.myWorksStatusId == 1024,
                        'statusColor-green-2':
                        data.myWorksStatusId == 128,
                        'statusColor-green':
                          data.myWorksStatusId == 8 ||
                          data.myWorksStatusId == 2048 ||
                          data.myWorksStatusId == 262144,
                        'statusColor-blue':
                          data.myWorksStatusId == 4 ||
                          data.myWorksStatusId == 4096
                      }">
                      {{ data.myWorksStatusTh }}
                    </td>

                    <td class="text-center">
                      {{ data.createDate | date : "d/M/yyyy" }}
                    </td>
                    <td class="text-center">
                      {{ data.currentOwnedBy }}
                    </td>
                    <ng-container *ngIf="
                        userRole === userRoleEnum.Finance ||
                        userRole === userRoleEnum.FinanceSupervisor ||
                        userRole === userRoleEnum.FinanceManager
                      ">
                      <td class="text-center">
                        <div class="d-flex">
                          <div class="icon">
                            <button class="btn btn-icon rounded-circle btn-outline-primary"
                              (click)="onFinanceCreateSlipClick(data.invoiceId)" *ngIf="
                                viewByInvoice &&
                                (userRole == userRoleEnum.Finance ||
                                  userRole == userRoleEnum.FinanceSupervisor ||
                                  userRole == userRoleEnum.FinanceManager)
                              ">
                              <span [data-feather]="'search'"></span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="
                        userRole !== userRoleEnum.Finance &&
                        userRole !== userRoleEnum.FinanceSupervisor &&
                        userRole !== userRoleEnum.FinanceManager
                      ">
                      <td class="text-center">
                        <div class="d-flex">
                          <div class="icon">
                            <button class="btn btn-icon rounded-circle btn-outline-primary"
                              (click)="onFinanceCreateSlipClick(data.invoiceId)" *ngIf="
                                viewByInvoice &&
                                (userRole == userRoleEnum.Finance ||
                                  userRole == userRoleEnum.Finance ||
                                  userRole == userRoleEnum.Finance)
                              ">
                              <span [data-feather]="'search'"></span>
                            </button>
                          </div>
                          <!-- <div class="icon">
                            <button
                              class="table-action-btn"
                              (click)="onInvoiceClicked(data.invoiceId)"
                              *ngIf="viewByInvoice"
                            >
                              <img
                                src="../../../../../assets/images/icons/button-icons/Edit.png"
                                alt="Edit"
                              />
                            </button>
                          </div>
                          
                          <div class="icon">
                            <button
                              class="table-action-btn"
                              (click)="openPeriodModal()"
                              *ngIf="!viewByInvoice"
                            >
                              <img
                                src="../../../../../assets/images/icons/button-icons/Edit.png"
                                alt="Edit"
                              />
                            </button>
                          </div> -->

                          <div class="icon">
                            <button class="table-action-btn" (click)="
                                onMyTaskClicked(
                                  data.customerId,
                                  data.monthNumber,
                                  data.yearNumber
                                )
                              " *ngIf="!viewByInvoice">
                              <img src="../../../../../assets/images/icons/button-icons/See-Detail.png" alt="Detail" />
                            </button>

                            <button class="table-action-btn" (click)="onInvoiceClicked(data.invoiceId)"
                              *ngIf="viewByInvoice">
                              <img src="../../../../../assets/images/icons/button-icons/See-Detail.png" alt="Detail" />
                            </button>
                          </div>

                          <!-- <div class="icon">
                            <button
                              class="table-action-btn"
                              onclick="iconClicked()"
                            >
                              <img
                                src="../../../../../assets/images/icons/button-icons/more-option.png"
                                alt="More option"
                              />
                            </button>
                            <div class="tolltip">
                              <a
                                class="tolltip-action"
                                (click)="confirmJob(confirmJobDialog, data)"
                                >Confirm</a
                              >
                              <hr />
                              <a
                                class="tolltip-action"
                                (click)="rejectJob(rejectJobDialog, data)"
                                >Reject</a
                              >
                              <hr />
                            </div>
                          </div> -->

                          <ng-container *ngIf="!viewByInvoice && isNotFinance()">
                            <div class="icon">
                              <button class="table-action-btn" (click)="transferIconClicked(data, i)">
                                <img src="../../../../../assets/images/icons/button-icons/businessman.png"
                                  alt="Businessman" />
                              </button>
                            </div>
                            <div class="icon">
                              <button class="table-action-btn" *ngIf="data.isFullPeriodModel && data.canTransferWorks "
                                (click)="tempTransferIconClicked(data, i)">
                                <img src="../../../../../assets/images/icons/button-icons/transfer.png"
                                  alt="Transfer" />
                              </button>
                            </div>
                          </ng-container>

                        </div>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="
                        (userRole !== userRoleEnum.Finance &&
                        userRole !== userRoleEnum.FinanceSupervisor &&
                        userRole !== userRoleEnum.FinanceManager) &&
                        viewByInvoice
                      ">
                      <td class="text-center">
                        {{ data.financeRemark[0] }}
                      </td>
                    </ng-container>

                    <!-- <ng-container
                      *ngIf="
                        userRole === userRoleEnum.Finance ||
                        userRole === userRoleEnum.FinanceSupervisor ||
                        userRole === userRoleEnum.FinanceManager ||
                        userRole === userRoleEnum.SuperAdmin
                      "
                    > -->
                    <ng-container *ngIf="
                        userRole === userRoleEnum.Finance ||
                        userRole === userRoleEnum.FinanceSupervisor ||
                        userRole === userRoleEnum.FinanceManager
                      ">
                      <td class="text-center">
                        <button
                          *ngIf="data.evidenceIdList.length < 15 && !financeRejectedCondition(data.myWorksStatusId)"
                          class="btn btn-primary" (click)="addFinanceRow(i)">
                          <i data-feather="plus"></i>
                        </button>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="
                        userRole === userRoleEnum.Finance ||
                        userRole === userRoleEnum.FinanceSupervisor ||
                        userRole === userRoleEnum.FinanceManager
                      ">
                      <td class="">
                        <button class="btn btn-success" *ngIf="!financeRejectedCondition(data.myWorksStatusId)"
                          (click)="submitFinance(i)">
                          <i data-feather="check"></i>
                        </button>

                        <button class="btn btn-warning ml-1"
                          *ngIf="(userRole === userRoleEnum.FinanceSupervisor || userRole === userRoleEnum.FinanceManager)
                          && !checkDiffIsZero(i) && data.myWorksStatusId == 2048 && !financeRejectedCondition(data.myWorksStatusId)"
                          (click)="confirmFinance(i,data)">
                          ปิดงาน
                        </button>
                      </td>
                    </ng-container>
                    <!-- <td class="text-center">
                        <ng_container *ngFor="let item of data.paymentAmount">
                          <input type="text" class="form-control" />
                        </ng_container>
                      </td> -->
                    <!-- </ng-container> -->
                    <ng-container *ngIf="
                        userRole === userRoleEnum.Finance ||
                        userRole === userRoleEnum.FinanceSupervisor ||
                        userRole === userRoleEnum.FinanceManager
                      ">
                      <td class="text-right table-border-right" style="min-width: 180px">
                        {{ data.totalPrice }}
                      </td>
                      <td class="text-center">
                        <ng-container *ngFor="
                            let item of data.paymentAmount;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <div class="d-flex" style="margin-block: -1rem;">
                            <div class="py-auto" style="width: 75px; align-self: center">
                              <button class="btn btn-danger btn-sm my-auto"
                                *ngIf="data.paymentAmount.length > 1 && (!data.evidenceStatusList[ind] || userRole !== userRoleEnum.Finance)"
                                (click)="removePaymentRow(i, ind)">
                                <i data-feather="x"></i>
                              </button>
                            </div>

                            <div style="width: 180px">
                              <div class="fake-form-control text-end finance-input my-1 px-0"
                                id="payment-amount-text-{{ i }}-{{ ind }}" [ngClass]="{
                                  'fake-form-control-disabled':
                                    (data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)
                                }" (click)="
                                  !((data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)) &&
                                    showNumberInput('payment-amount', i, ind)
                                ">
                                <div class="fake-form-control-text finance-input"
                                  id="payment-amount-subtext-{{ i }}-{{ ind }}">
                                  {{
                                  data.paymentAmount[ind]
                                  ? formatNumberToMoney(
                                  data.paymentAmount[ind]
                                  )
                                  : "0.00"
                                  }}
                                </div>
                              </div>
                              <input id="payment-amount-input-{{ i }}-{{ ind }}" type="text"
                                class="form-control my-1 finance-input w-100" [(ngModel)]="data.paymentAmount[ind]"
                                [value]="
                                  data.paymentAmount[ind]
                                    ? data.paymentAmount[ind]
                                    : 0
                                " (focus)="removeValidateClass(i, ind)" (blur)="
                                  hideNumberInput('payment-amount', i, ind)
                                " [disabled]="
                                  (data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)
                                " hidden (ngModelChange)="calculateDiffValue(i)"
                                oninput="this.value = this.value.replace(/[^0-9.-]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                            </div>
                          </div>
                        </ng-container>
                      </td>

                      <td class="text-center">
                        <ng-container *ngFor="
                            let item of data.diffValueList;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <input type="text" class="form-control my-1 finance-input"
                            [(ngModel)]="data.diffValueList[ind]" [value]="
                              data.diffValueList[ind]
                                ? data.diffValueList[ind]
                                : 0
                            " disabled />
                        </ng-container>
                      </td>

                      <td class="text-center" style="min-width: 250px">
                        <ng-container *ngFor="
                            let item of data.paymentTypeList;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <ng-select class="w-100 my-1" data-boundary="viewport" [(ngModel)]="data.paymentTypeList[ind]"
                            [clearable]="false" [disabled]="
                              (data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)
                            " (ngModelChange)="onSelectPaymentTypeChanged(i, ind)">
                            <ng-option *ngFor="let payment of paymentType" [value]="payment.id" [selected]="
                                payment.id == data.paymentTypeList[ind]
                              ">
                              {{ payment.name }}
                            </ng-option>
                          </ng-select>
                        </ng-container>
                      </td>

                      <td class="text-center" style="min-width: 250px">
                        <ng-container *ngFor="
                            let item of data.bankList;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <ng-select class="w-100 my-1" [(ngModel)]="data.bankList[ind]" [clearable]="false" [disabled]="
                              (data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance )|| financeRejectedCondition(data.myWorksStatusId)
                            " (ngModelChange)="onSelectChanged()">
                            <ng-option *ngFor="let bank of bankIdList" [value]="bank.name"
                              [selected]="bank.name == data.bankList[ind]">
                              {{ bank.name }}
                            </ng-option>
                          </ng-select>
                        </ng-container>
                      </td>

                      <td style="min-width: 150px">
                        <ng-container *ngFor="
                            let item of data.payDate;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <div style="padding-top: 7px; padding-bottom: 7px">
                            <input id="payDate-{{ ind }}" type="text" [bsConfig]="dateOptions" placeholder="DD/MM/YYYY"
                              class="form-control" [disabled]="
                                (data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)
                              " bsDatepicker [(bsValue)]="data.payDate[ind]" />
                          </div>
                        </ng-container>
                      </td>

                      <td style="min-width: 150px">
                        <ng-container *ngFor="
                            let item of data.payDate;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <div style="padding-top: 7px; padding-bottom: 7px">
                            <!-- <timepicker id="payTime-{{ i }}-{{ ind }}" [(bsValue)]="data.payTime[ind]"
                              [showMeridian]="isMeridian"
                              [readonlyInput]="(data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)"
                              [showSpinners]="true"></timepicker> -->

                            <ng-template #popTemplate>
                              <timepicker [showMeridian]="false" class="timepicker-table" id="payTime-{{ i }}-{{ ind }}"
                                [(ngModel)]="data.payTime[ind]" [minuteStep]="1"
                                [readonlyInput]="(data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)">
                              </timepicker>
                            </ng-template>
                            <input class="form-control"
                              *ngIf="!(data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)"
                              (ngModelChange)="data.payTime[ind]=$event" value="{{ data.payTime[ind] | date: 'HH:mm' }}"
                              [popover]="popTemplate" [outsideClick]="true" placement="bottom" />

                            <input class="form-control"
                              *ngIf="(data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)"
                              disabled value="{{ data.payTime[ind] | date: 'HH:mm' }}" />

                          </div>
                        </ng-container>
                      </td>

                      <!-- 
                      <td style="min-width: 150px">
                        <ng-container *ngFor="
                            let item of data.payTime;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <div style="padding-top: 7px; padding-bottom: 7px">
                            <input id="payDate-{{ ind }}" type="text" [bsConfig]="dateOptions" placeholder="DD/MM/YYYY"
                              class="form-control" [disabled]="
                                (data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)
                              " bsDatepicker [(bsValue)]="data.payDate[ind]" />
                          </div>
                        </ng-container>
                      </td> -->

                      <!-- <td style="min-width: 150px">
                        <ng-container *ngFor="
                            let item of data.payTime;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <div style="padding-top: 7px; padding-bottom: 7px"
                            *ngIf="!((data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId))">
                            <ng2-flatpickr id="payTime-{{ i }}-{{ ind }}" placeholder="00:00" [config]="timeOptions"
                              [(ngModel)]="data.payTime[ind]" [setDate]="data.payTime[ind]" class="mb-1" [value]="
                                data.payTime[ind] ? data.payTime[ind] : 0
                              ">
                            </ng2-flatpickr>
                          </div>

                          <div style="padding-top: 7px !important; padding-bottom: 7px !important;"
                            *ngIf="(data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)">
                            <div class="fake-form-control fake-form-control-disabled">
                              <div class="fake-form-control-text">
                                {{ data.payTime[ind] | date : "HH:mm" }}
                              </div>
                            </div>

                          </div>
                        </ng-container>
                      </td> -->

                      <td class="text-center" scope="col">
                        <ng-container *ngFor="
                            let item of data.financeRemark;
                            index as ind;
                            trackBy: trackByFn
                          ">
                          <input style="width: 12em" type="text" class="form-control my-1" [disabled]="
                              (data.evidenceStatusList[ind] && userRole === userRoleEnum.Finance) || financeRejectedCondition(data.myWorksStatusId)
                            " [(ngModel)]="data.financeRemark[ind]" [value]="
                              data.financeRemark[ind]
                                ? data.financeRemark[ind]
                                : 0
                            " />
                        </ng-container>
                      </td>
                      <ng-container *ngIf="userRole === userRoleEnum.FinanceManager">
                        <td></td>
                      </ng-container>
                      <td class="text-center">
                        <div class="d-flex">
                          <div class="icon">
                            <button class="table-action-btn" (click)="
                                onMyTaskClicked(
                                  data.customerId,
                                  data.monthNumber,
                                  data.yearNumber
                                )
                              " *ngIf="!viewByInvoice">
                              <img src="../../../../../assets/images/icons/button-icons/See-Detail.png" alt="Detail" />
                            </button>

                            <button class="table-action-btn" (click)="onInvoiceClicked(data.invoiceId)"
                              *ngIf="viewByInvoice">
                              <img src="../../../../../assets/images/icons/button-icons/See-Detail.png" alt="Detail" />
                            </button>
                          </div>

                          <ng-container *ngIf="isNotFinance()">
                            <div class="icon" *ngIf="!viewByInvoice">
                              <button class="table-action-btn" (click)="transferIconClicked(data, i)">
                                <img src="../../../../../assets/images/icons/button-icons/businessman.png"
                                  alt="Businessman" />
                              </button>
                            </div>
                            <div class="icon">
                              <button class="table-action-btn" *ngIf="data.isFullPeriodModel && data.canTransferWorks "
                                (click)="tempTransferIconClicked(data, i)">
                                <img src="../../../../../assets/images/icons/button-icons/transfer.png"
                                  alt="Transfer" />
                              </button>
                            </div>
                          </ng-container>

                        </div>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row my-2">
          <div class="col-12">
            <app-pagination [currentPage]="page" [totalPages]="totalPages" (pageChange)="onPageChange($event)"
              (sizePageChange)="onSizePageChange($event)"></app-pagination>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="userRole === userRoleEnum.Accountant">
        <div class="card-body p-0">
          <div class="table-responsive" #AccountantTableWrapper (scroll)="syncScroll($event)">
            <div class="accountant-table" [ngClass]="{
                'viewby-invoice': viewByInvoice,
                'not-viewby-invoice': !viewByInvoice
              }">
              <table class="table">
                <thead class="my-works-header content-wrapper container-xxl p-0 is-sticky-top-window" #stickyAccountantHeader
                  *ngIf="isShowStickyHeader">
                  <ng-container *ngTemplateOutlet="AccTableheaderDetail"></ng-container>
                </thead>
                <thead class="my-works-header" #NotStickyAccountantHeader>
                  <ng-container *ngTemplateOutlet="AccTableheaderDetail"></ng-container>
                </thead>
                <tbody #AccountantDataTable>
                  <tr *ngIf="tableListData.length <= 0">
                    <td colspan="100%" class="text-center">ไม่มีข้อมูลงาน</td>
                  </tr>
                  <tr *ngFor="let data of tableListData; let i = index">
                    <td scope="row">
                      <input type="checkbox" class="default-checkbox" [checked]="data.isChecked"
                        (change)="selectItem($event.target.checked, data)" />
                    </td>
                    <td class="text-center">
                      {{ (page - 1) * pageLength + i + 1 }}
                    </td>
                    <td class="text-start">
                      <div class="name-tolltip">
                        <div style="overflow: hidden; text-overflow: ellipsis">
                          <a (click)="
                              onMyTaskClicked(
                                data.customerId,
                                data.monthNumber,
                                data.yearNumber
                              )
                            ">
                            {{ data.name }}
                          </a>
                        </div>
                        <div class="tolltip">
                          {{ data.name }}
                        </div>
                      </div>
                    </td>
                    <td class="text-center table-border-right" *ngIf="!viewByInvoice">
                      {{ data.periodNumber }}
                    </td>
                    <td class="text-center table-border-right" *ngIf="viewByInvoice">
                      <a (click)="onInvoiceClicked(data.invoiceId)">
                        {{ data.invoiceCode ? data.invoiceCode : "-" }}
                      </a>
                    </td>
                    <td class="text-center table-border-right">
                      <!-- {{ getMonthName(data.monthNumber) }} -->
                      {{ data.monthNameTh }}
                    </td>
                    <td class="text-center table-border-right">
                      {{ toNumber(data.yearNumber) + 543 }}
                    </td>
                    <td class="text-center table-border-right" [ngClass]="{
                        'statusColor-black': data.myWorksStatusId == 1,
                        'statusColor-gray':
                          data.myWorksStatusId == 32768 ||
                          data.myWorksStatusId == 65536 ||
                          data.myWorksStatusId == 131072,
                        'statusColor-red':
                          data.myWorksStatusId == 16 ||
                          data.myWorksStatusId == 32 ||
                          data.myWorksStatusId == 8192 ||
                          data.myWorksStatusId == 16384,
                        'statusColor-yellow':
                          data.myWorksStatusId == 2 ||
                          data.myWorksStatusId == 1024,
                        'statusColor-green-2':
                        data.myWorksStatusId == 128,
                        'statusColor-green':
                          data.myWorksStatusId == 8 ||
                          data.myWorksStatusId == 2048 ||
                          data.myWorksStatusId == 262144,
                        'statusColor-blue':
                          data.myWorksStatusId == 4 ||
                          data.myWorksStatusId == 4096
                      }">
                      {{ data.myWorksStatusTh }}
                    </td>

                    <td class="text-right table-border-right" style="min-width: 180px"
                      *ngFor="let item of data.documentValueList">
                      <div>
                        <div style="cursor: pointer" ngbTooltip="{{
                                item.remarks ? item.remarks : ''
                              }}">
                          <div *ngIf="item.paymentType === 0">
                            <div class="text-primary d-flex justify-content-end">
                              <ng-container *ngIf="
                                      item.isSubmitTaxForm;
                                      else docMarkerTemplate1
                                    ">
                                <div class="text-warning mr-1">
                                  <i data-feather="star" [size]="18"></i>
                                </div>
                              </ng-container>
                              <ng-template #docMarkerTemplate1>
                                <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                  <i data-feather="check-square" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                  <i data-feather="x" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                  <i data-feather="alert-circle" [size]="18"></i>
                                </div>
                              </ng-template>

                              {{ item.price }}
                            </div>
                          </div>
                          <div *ngIf="item.paymentType === 1">
                            <div class="text-success d-flex justify-content-end">
                              <ng-container *ngIf="
                                                                        item.isSubmitTaxForm;
                                                                        else docMarkerTemplate2
                                                                        ">
                                <div class="text-warning mr-1">
                                  <i data-feather="star" [size]="18"></i>
                                </div>
                              </ng-container>
                              <ng-template #docMarkerTemplate2>
                                <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                  <i data-feather="check-square" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                  <i data-feather="x" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                  <i data-feather="alert-circle" [size]="18"></i>
                                </div>
                              </ng-template>
                              {{ item.price }}
                            </div>
                          </div>
                          <div *ngIf="
                                  item.paymentType !== 1 &&
                                  item.paymentType !== 0
                                ">
                            <div class="d-flex justify-content-end">
                              <ng-container *ngIf="
                                                                        item.isSubmitTaxForm;
                                                                        else docMarkerTemplate3
                                                                        ">
                                <div class="text-warning mr-1">
                                  <i data-feather="star" [size]="18"></i>
                                </div>
                              </ng-container>
                              <ng-template #docMarkerTemplate3>
                                <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                  <i data-feather="check-square" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                  <i data-feather="x" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                  <i data-feather="alert-circle" [size]="18"></i>
                                </div>
                              </ng-template>
                              {{ item.price }}
                            </div>
                          </div>

                          <hr />

                          <div *ngIf="item.paymentType === 0">
                            <div class="text-pink d-flex justify-content-end">
                              <ng-container *ngIf="
                                      item.isSubmitTaxForm;
                                      else docMarkerTemplate4
                                    ">
                                <div class="text-warning mr-1">
                                  <i data-feather="star" [size]="18"></i>
                                </div>
                              </ng-container>
                              <ng-template #docMarkerTemplate4>
                                <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                  <i data-feather="check-square" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                  <i data-feather="x" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                  <i data-feather="alert-circle" [size]="18"></i>
                                </div>
                              </ng-template>
                              {{ item.fineAmount }}
                            </div>
                          </div>
                          <div *ngIf="item.paymentType === 1">
                            <div class="text-danger d-flex justify-content-end">
                              <ng-container *ngIf="
                                      item.isSubmitTaxForm;
                                      else docMarkerTemplate5
                                    ">
                                <div class="text-warning mr-1">
                                  <i data-feather="star" [size]="18"></i>
                                </div>
                              </ng-container>
                              <ng-template #docMarkerTemplate5>
                                <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                  <i data-feather="check-square" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                  <i data-feather="x" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                  <i data-feather="alert-circle" [size]="18"></i>
                                </div>
                              </ng-template>
                              {{ item.fineAmount }}
                            </div>
                          </div>
                          <div *ngIf="
                                  item.paymentType !== 1 &&
                                  item.paymentType !== 0
                                ">
                            <div class="d-flex justify-content-end">
                              <ng-container *ngIf="
                                      item.isSubmitTaxForm;
                                      else docMarkerTemplate6
                                    ">
                                <div class="text-warning mr-1">
                                  <i data-feather="star" [size]="18"></i>
                                </div>
                              </ng-container>
                              <ng-template #docMarkerTemplate6>
                                <div class="text-success mr-1" *ngIf="item.documentStateEnum == 1">
                                  <i data-feather="check-square" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.documentStateEnum == 2">
                                  <i data-feather="x" [size]="18"></i>
                                </div>
                                <div class="text-danger mr-1" *ngIf="item.isOverDue">
                                  <i data-feather="alert-circle" [size]="18"></i>
                                </div>
                              </ng-template>
                              {{ item.fineAmount }}
                            </div>
                          </div>
                        </div>

                      </div>
                    </td>
                    <td class="text-right table-border-right" style="min-width: 180px">
                      <div class="d-flex justify-content-end">
                        <div class="text-success mr-1" *ngIf="data.isServiceMarkClear">
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isServiceReject">
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isServiceOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ data.summationOfAllPriceService }}
                      </div>
                    </td>
                    <td class="text-right table-border-right" style="min-width: 180px">
                      <div class="d-flex justify-content-end">
                        <div class="text-success mr-1" *ngIf="data.isPriceMarkClear">
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isPriceReject">
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isPriceOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ data.summationOfPrice }}
                      </div>
                    </td>
                    <td class="text-right table-border-right" style="min-width: 180px">
                      <div class="d-flex justify-content-end">
                        <div class="text-success mr-1" *ngIf="data.isFineMarkClear">
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isFineReject">
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="data.isFineOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ data.summationOfFine }}
                      </div>
                    </td>
                    <td class="text-right table-border-right" style="min-width: 180px">
                      {{ data.totalPrice }}
                    </td>
                    <td class="text-center" style="min-width: 180px">
                      {{ data.currentOwnedBy }}
                    </td>
                    <!-- <td>
                      <button
                        class="new-document-btn disabled"
                        (click)="markerJob(data)"
                        disabled
                      >
                        ปิดงาน
                      </button>
                    </td> -->
                    <td>
                      <div class="d-flex">
                        <div class="icon"
                          *ngIf="data.isFullPeriodModel || (!data.isFullPeriodModel && data.canTransferWorks)">
                          <button class="table-action-btn" (click)="transferIconClicked(data, i)">
                            <img src="../../../../../assets/images/icons/button-icons/businessman.png"
                              alt="Businessman" />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row my-2">
            <div class="col-12">
              <app-pagination [currentPage]="page" [totalPages]="totalPages" (pageChange)="onPageChange($event)"
                (sizePageChange)="onSizePageChange($event)"></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #complete let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 d-flex justify-content-center mt-5">
        <img style="width: 50%" src="../../../../../assets/images/icons/file-icons/completeIcon.png" alt="complete" />
      </div>
      <div class="col-12 text-center my-5">
        <h3 style="color: #4fcd9c; font-size: 40px; font-weight: 300">
          Complete
        </h3>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancel let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 d-flex justify-content-center mt-5">
        <img style="width: 50%" src="../../../../../assets/images/icons/file-icons/cancelIcon.png" alt="complete" />
      </div>
      <div class="col-12 text-center my-5">
        <h3 style="color: #e62918; font-size: 40px; font-weight: 300">
          Cancel
        </h3>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmJobDialog let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 text-center my-3">
        <h3 class="text-color-main">กรุณายืนยันความถูกต้อง</h3>
      </div>
      <div class="col-12 mb-5 text-center">
        <h3>ลูกค้า: {{ rowData.customerName }}</h3>
        <ng-container *ngIf="!viewByInvoice">
          <h3>รอบที่: {{ rowData.periodNumber }}</h3>
          <h3>เดือน: {{ rowData.monthNameTh }}</h3>
        </ng-container>
        <ng-container *ngIf="viewByInvoice">
          <h3>หมายเลขเอกสาร: {{ rowData.invoiceCode }}</h3>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss(cancel)">
      ยกเลิก
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.dismiss(''); ConfirmDocument(rowData)">
      ยืนยัน
    </button>
  </div>
</ng-template>

<ng-template #rejectJobDialog let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center mb-1">
      <div class="col-12 text-center my-1">
        <label class="text-color-main">กรุณาระบุเหตุผลการคืนงาน</label>
      </div>
      <textarea [(ngModel)]="rejectJobReason" name="companyAddress" class="form-control" rows="3"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss(cancel)">
      ยกเลิก
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close(rejectJobDialog02)">
      ยืนยัน
    </button>
  </div>
</ng-template>

<ng-template #rejectJobDialog02 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center mb-1">
      <div class="col-12 text-center my-3">
        <h3 class="text-color-main">กรุณายืนยันความถูกต้อง</h3>
      </div>
      <div class="col-12 mb-5 text-center">
        <h3>ลูกค้า: {{ rowData.customerName }}</h3>
        <ng-container *ngIf="!viewByInvoice">
          <h3>รอบที่: {{ rowData.periodNumber }}</h3>
          <h3>เดือน: {{ rowData.monthNameTh }}</h3>
        </ng-container>
        <ng-container *ngIf="viewByInvoice">
          <h3>หมายเลขเอกสาร: {{ rowData.invoiceCode }}</h3>
        </ng-container>

        <h3>สาเหตุ : {{ rejectJobReason }}</h3>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel')">
      ยกเลิก
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.dismiss(''); RejectDocument(rowData)">
      ยืนยัน
    </button>
  </div>
</ng-template>

<ng-template #NonAccTableheaderDetail>
  <tr>
    <th class="text-center" scope="col">
      <input type="checkbox" class="default-checkbox" (change)="selectAll($event.target.checked)"
        [checked]="isSelectAll" />
    </th>

    <th class="text-center" scope="col">NO.</th>
    <th class="text-center" scope="col">ลูกค้า</th>
    <th class="text-center" scope="col" *ngIf="!viewByInvoice">
      รอบที่
    </th>
    <th class="text-center" scope="col" *ngIf="viewByInvoice">
      หมายเลขเอกสาร
    </th>
    <th class="text-center" scope="col">เดือน</th>
    <th class="text-center" scope="col">ปี</th>
    <ng-container *ngIf="
        userRole !== userRoleEnum.Finance &&
        userRole !== userRoleEnum.FinanceSupervisor &&
        userRole !== userRoleEnum.FinanceManager
      ">
      <th class="text-center" scope="col" style="min-width: 180px;" *ngFor="let item of tableHeader">
        {{ item.itemName }}
      </th>
    </ng-container>
    <th class="text-center" scope="col">ค่าบริการ</th>
    <th class="text-center" scope="col">รวมภาษี</th>
    <th class="text-center" scope="col">ค่าปรับ</th>
    <ng-container *ngIf="
        userRole !== userRoleEnum.Finance &&
        userRole !== userRoleEnum.FinanceSupervisor &&
        userRole !== userRoleEnum.FinanceManager
      ">
      <th class="text-center" scope="col">รวม</th>
    </ng-container>
    <th class="text-center" scope="col">สถานะ</th>
    <th class="text-center" scope="col">UPDATE DATE</th>
    <th class="text-center" scope="col">CREATE BY</th>
    <ng-container *ngIf="
        userRole === userRoleEnum.Finance ||
        userRole === userRoleEnum.FinanceSupervisor ||
        userRole === userRoleEnum.FinanceManager
      ">
      <th class="text-center" scope="col">Slip</th>
    </ng-container>
    <ng-container *ngIf="
        userRole !== userRoleEnum.Finance &&
        userRole !== userRoleEnum.FinanceSupervisor &&
        userRole !== userRoleEnum.FinanceManager
      ">
      <th class="text-center" scope="col">การจัดการ</th>
    </ng-container>
    <ng-container *ngIf="userRole !== userRoleEnum.Finance &&
    userRole !== userRoleEnum.FinanceSupervisor &&
    userRole !== userRoleEnum.FinanceManager">
      <th class="text-center" scope="col" *ngIf="viewByInvoice">
        Remark
      </th>
    </ng-container>

    <ng-container *ngIf="
        userRole === userRoleEnum.Finance ||
        userRole === userRoleEnum.FinanceSupervisor ||
        userRole === userRoleEnum.FinanceManager
      ">
      <th class="text-center" scope="col"></th>
      <th class="text-center" scope="col"></th>
      <th class="text-center" scope="col">รวม</th>
      <th class="text-center" scope="col">
        จำนวนเงินที่ลูกค้าชำระ
      </th>
      <th class="text-center" scope="col">ผลต่าง</th>
      <th class="text-center" scope="col">วิธีการชำระ</th>
      <th class="text-center" scope="col">ธนาคาร</th>
      <th class="text-center" scope="col">วันที่ชำระ</th>
      <th class="text-center" scope="col">เวลาที่ชำระ</th>
      <th class="text-center" scope="col">Remark</th>
      <ng-container *ngIf="userRole === userRoleEnum.FinanceManager">
        <th class="text-center" scope="col">Checked</th>
      </ng-container>
      <th class="text-center" scope="col">การจัดการ</th>
    </ng-container>
  </tr>
</ng-template>

<ng-template #AccTableheaderDetail>
  <tr>
    <th scope="col">
      <input type="checkbox" class="default-checkbox" (click)="selectAll($event.target.checked)"
        [checked]="isSelectAll" />
    </th>
    <th class="text-center" scope="col">NO.</th>
    <th class="text-center" scope="col">ลูกค้า</th>
    <th class="text-center" scope="col" *ngIf="!viewByInvoice">
      รอบที่
    </th>
    <th class="text-center" scope="col" *ngIf="viewByInvoice">
      หมายเลขเอกสาร
    </th>
    <th class="text-center" scope="col">เดือน</th>
    <th class="text-center" scope="col">ปี</th>
    <th class="text-center" scope="col">สถานะ</th>
    <th class="text-center" scope="col" *ngFor="let item of tableHeader">
      {{ item.itemName }}
    </th>
    <th class="text-center" scope="col">ค่าบริการ</th>
    <th class="text-center" scope="col">รวมภาษี</th>
    <th class="text-center" scope="col">ค่าปรับ</th>
    <th class="text-center" scope="col">รวม</th>
    <th class="text-center" scope="col">CREATE BY</th>
    <!-- <th class="text-center" scope="col"></th> -->
    <th class="text-center" scope="col"></th>
    <!-- <th class="text-center" scope="col">Remark</th> -->
  </tr>
</ng-template>