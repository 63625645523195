import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';
import { Observable } from 'rxjs';
import { get } from 'http';

@Injectable({ providedIn: 'root' })
export class UserService {
  private apiBaseUrl: string = `${environment.apiUrl}/api`;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {}

  get API_URL() {
    return this.apiBaseUrl;
  }

  get API_HEADERS() {
    return this.httpHeaders;
  }

  SetHttpHeaders(token): void {
    this.httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`)
      .set('sec-fetch-dest', 'empty')
      .set('sec-fetch-mode', 'cors')
      .set('sec-fetch-site', 'same-origin');
  }

  getAll() {
    return this.http.get<User[]>(`${this.apiBaseUrl}/users`, {
      headers: this.httpHeaders,
    });
  }

  getById(id: number) {
    return this.http.get<User>(`${this.apiBaseUrl}/users/${id}`, {
      headers: this.httpHeaders,
    });
  }

  getProvince() {
    return this.http.get<any>(`${this.apiBaseUrl}/MasterProvince`, {
      headers: this.httpHeaders,
    });
  }

  getDepartment() {
    return this.http.get<any>(`${this.apiBaseUrl}/DepartmentFilter`, {
      headers: this.httpHeaders,
    });
  }

  getTeam(departmentid: number) {
    return this.http.get<any>(
      `${this.apiBaseUrl}/TeamFilter/${departmentid}/getTeam`,
      { headers: this.httpHeaders }
    );
  }

  getPosition(departmentid: number, teamid: number) {
    return this.http.get<any>(
      `${this.apiBaseUrl}/PositionFilter/${departmentid}/getPosition?teamId=${teamid}`,
      { headers: this.httpHeaders }
    );
  }

  getPositionByDepartment(departmentid: number) {
    console.log(departmentid);

    return this.http.get<any>(
      `${this.apiBaseUrl}/PositionFilter/${departmentid}/getPositionForSeach`,
      { headers: this.httpHeaders }
    );
  }

  getAllPosition() {
    return this.http.get<any>(`${this.apiBaseUrl}/position`, {
      headers: this.httpHeaders,
    });
  }

  getManager(departmentid: number, positionId: number) {
    return this.http.get<any>(
      `${this.apiBaseUrl}/ManagerFilter/getManager?departmentid=${departmentid}&positionId=${positionId}`,
      { headers: this.httpHeaders }
    );
  }

  getSupervisor(departmentid: number, teamId: number, positionId: number) {
    return this.http.get<any>(
      `${this.apiBaseUrl}/SupervisorFilter/getSupervisor/?departmentid=${departmentid}&teamid=${teamId}&positionId=${positionId}`,
      { headers: this.httpHeaders }
    );
  }

  getSubDistrict(districtId: number) {
    let params = new HttpParams()
      .set('DistrictId', districtId)
      .set('IsActive', 'true')
      .set('IsDelete', 'false');

    const url = `${this.apiBaseUrl}/MasterSubDistrict`;
    return this.http.get<any>(url, {
      params: params,
      headers: this.httpHeaders,
    });
  }

  getDistrict(provinceId: number) {
    let params = new HttpParams()
      .set('ProvinceId', provinceId)
      .set('isActive', 'true')
      .set('isDelete', 'false');

    const url = `${this.apiBaseUrl}/MasterDistrict`;
    return this.http.get<any>(url, {
      params: params,
      headers: this.httpHeaders,
    });
  }

  // Manage User

  getManageUserList(
    page: number,
    pageLength: number,
    officerName: string,
    departmentId: string,
    filterPositionId: string,
    status: number
  ): Observable<any> {
    let url = `${environment.apiUrl}/api/user?Page=${page}&PageLength=${pageLength}`;
    if (departmentId != null && departmentId != '') {
      url += `&DepartmentId=${departmentId}`;
    }

    if (filterPositionId != null && filterPositionId != '') {
      url += `&PositionId=${filterPositionId}`;
    }
    if (officerName != null && officerName != '') {
      url += `&FullName=${officerName}`;
    }
    if (status == 2) {
      url += `&IsActive=true`;
    } else if (status == 3) {
      url += `&IsActive=false`;
    }
    const token = JSON.parse(localStorage.getItem('token'));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.http.get<any>(url, httpOptions);
  }

  // getManageUseDetail(id:string) : Observable<any> {
  //   const url = `${this.apiBaseUrl}/user/${id}`;
  //   return this.http.get<any>(url, { headers : this.httpHeaders });
  // }
  getManageUseDetail(id: string): Observable<any> {
    const url = `${this.apiBaseUrl}/employee/${id}/employeemanage`;
    return this.http.get<any>(url, { headers: this.httpHeaders });
  }

  getSearchManageUserList(
    page: number,
    pageLength: number,
    firstName: string | null,
    roleSetName: string | null,
    userStatus: boolean | null
  ): Observable<any> {
    let url = `${this.apiBaseUrl}/user?Page=${page}&PageLength=${pageLength}`;
    if (firstName != null && firstName != '') {
      url += `&Username=${firstName}`;
    }
    if (roleSetName != null && roleSetName != '') {
      url += `&RoleSetName=${roleSetName}`;
    }
    if (userStatus != null && userStatus != undefined) {
      url += `&IsActive=${userStatus}`;
    }

    return this.http.get<any>(url, { headers: this.httpHeaders });
  }
  // createManageUser
  createManageUser(user: User): Observable<any> {
    const url = `${this.apiBaseUrl}/employee/createuserandemployee`;
    return this.http.post<any>(url, user, { headers: this.httpHeaders });
  }

  // updateManageUser
  updateManageUser(id: string, value: User): Observable<any> {
    const url = `${this.apiBaseUrl}/employee/${id}`;
    return this.http.put<any>(url, value, { headers: this.httpHeaders });
  }

  checkUsernameIsUnique(username: string): Observable<any> {
    const url = `${this.apiBaseUrl}/user/this-username-is-unique?username=${username}`;
    return this.http.get<any>(url, { headers: this.httpHeaders });
  }

  checkEmailIsUnique(email: string): Observable<any> {
    const url = `${this.apiBaseUrl}/user/this-email-is-unique?email=${email}`;
    return this.http.get<any>(url, { headers: this.httpHeaders });
  }

  SetIsActiveData(employeeId: string, isActive: boolean): Observable<any> {
    const url = `${this.apiBaseUrl}/employee/${employeeId}/active/${isActive}`;
    return this.http.patch<any>(url, null, { headers: this.httpHeaders });
  }

}
